import React from 'react';
import ResponsiveAppBar from './common/Appbar';

const LaunchingSoon = () => {
  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#FFA500', // Orange
      color: '#FFFFFF',          // White
      fontFamily: 'Arial, sans-serif',
      fontSize: '2rem',
      fontWeight: 'bold',
      textAlign: 'center',
    },
  };

  return (
    <div className="App">
      <ResponsiveAppBar></ResponsiveAppBar>
      <div style={styles.container}>
      Launching Soon
    </div>
    </div>

  );
};

export default LaunchingSoon;
